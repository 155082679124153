<template>
    <!--    按门店设置投放广告-->
    <div class="AdvToStore">
        <el-card>
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-row>
                <el-col :span="6">
                    <el-row>
                        <el-button size="small" type="primary" plain @click="handlePickUpManagedDept"
                            >选择门店</el-button
                        >
                        <el-button size="small" type="primary" plain @click="handlePickUpAdvs">选择广告</el-button>
                        <el-button size="small" type="primary" @click="handleSave">保存</el-button>
                    </el-row>
                </el-col>
                <el-col :span="18">
                    <el-tag type="warning"><i class="el-icon-warning-outline" />选择门店</el-tag>
                    <el-tag type="warning"><i class="el-icon-circle-check" />选择广告</el-tag>
                    <el-tag type="warning">设置投放日期</el-tag>
                    <el-tag type="info">保存</el-tag>
                </el-col>
            </el-row>
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            :model="form"
            :rules="rules"
            ref="createForm"
            label-width="80px"
            size="small"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item prop="deptCode" label="机构">
                            <span>code: </span>
                            <el-input type="text" :value="form.deptCode" readonly style="width: 220px" />
                            <br />
                            <span>类型: </span><span>{{ model.dept.type | deptType }}</span>
                            <br />
                            <span>名称: </span>
                            <span>{{ model.dept.name }}</span>
                            <br />
                            <span>地址: </span>
                            <span>
                                {{ model.dept.provinceName }}&nbsp;&nbsp;{{ model.dept.cityName }}&nbsp;&nbsp;{{
                                    model.dept.districtName
                                }}
                            </span>
                            <span>&nbsp;&nbsp;</span>
                            <span>{{ model.dept.address }}</span>
                            <br />
                        </el-form-item>
                        <el-form-item prop="position" label="广告位置">
                            <el-radio-group v-model.number="model.position" :rules="rules.position">
                                <el-radio :label="1">收银机主屏</el-radio>
                                <el-radio :label="2">收银机副屏</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="设置投放日期" label-width="120px" prop="startTime">
                            <el-date-picker
                                v-model="model.dateRange"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                :picker-options="pickerOptions"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px">
                <div slot="header">
                    <span>已选择广告列表</span>
                </div>
                <el-row>
                    <el-form-item
                        label="已选择广告数量"
                        prop="advertCount"
                        :rules="rules.advertCount"
                        label-width="140px"
                    >
                        <el-input type="number" readonly :value="form.advertCount" />
                    </el-form-item>
                </el-row>
                <el-row :gutter="8">
                    <el-col
                        :span="4"
                        v-for="(adv, idx) in model.advs"
                        style="margin-bottom: 8px"
                        :key="adv.uploadAccessory"
                    >
                        <el-card>
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-image
                                        v-if="isImgAdvertFileType(adv.type)"
                                        style="width: 100px; height: 100px"
                                        :src="adv.uploadAccessory"
                                        :preview-src-list="[adv.uploadAccessory]"
                                    />
                                    <video
                                        v-else-if="isVideoAdvertFileType(adv.type)"
                                        style="width: 100px; height: 100px"
                                        :src="adv.uploadAccessory"
                                    />
                                </el-col>
                                <el-col :span="12" style="padding-top: 24px">
                                    <el-button
                                        size="mini"
                                        type="primary"
                                        :disabled="!adv.uploadAccessory"
                                        @click="handlePreview(adv.type, adv.uploadAccessory)"
                                        >预览</el-button
                                    >
                                    <br />
                                    <el-button
                                        style="margin-top: 8px"
                                        type="danger"
                                        plain
                                        size="mini"
                                        @click="handleAdvDelete(idx)"
                                        >删除
                                    </el-button>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <pick-up-advs ref="pickUpAdvs" @ok="handleSelectAdvsOk" multi />
        <pick-up-managed-dept ref="pickUpManagedDept" @ok="handleSelectManagedDeptOk" :multi="false" />
        <!--预览广告组件-->
        <advert-preview ref="advertPreview" />
    </div>
</template>

<script>
import PickUpAdvs from '../../../components/PickUpAdvs';
import PickUpManagedDept from '../../../components/PickUpManagedDept';
import AdvertUtils from 'js/AdvertUtils';
import AdvertPreview from 'views/menu/advert/manage/AdvertPreview';
import { postAfterConfirmed } from 'request/http';

export default {
    name: 'AdvToStore',
    components: { PickUpAdvs, PickUpManagedDept, AdvertPreview },
    data() {
        return {
            model: {
                dept: {},
                advs: [],
                dateRange: [],
                position: 1,
            },
            rules: {
                deptCode: { required: true, message: '请选择门店', trigger: 'change' },
                startTime: { required: true, message: '请选择投放日期', trigger: ['change'] },
                advertCount: { type: 'number', required: true, min: 1, message: '请选择广告', trigger: 'change' },
                position: { required: true, message: '请选择广告位置', trigger: 'change' },
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: '未来一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '未来一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '未来三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                ],
            },
        };
    },
    computed: {
        form() {
            const advertCodes = this.model.advs?.map((e) => e.code) || [];
            return {
                deptCode: this.model.dept?.code,
                startTime: this.model.dateRange?.[0],
                endTime: this.model.dateRange?.[1],
                adCodes: advertCodes,
                advertCount: advertCodes.length,
                position: this.model.position,
            };
        },
    },
    methods: {
        handlePickUpAdvs() {
            this.$refs.pickUpAdvs.show();
        },
        handleSelectAdvsOk(advs = []) {
            //    过滤出未添加过的广告，并追加到已选择广告列表中
            this.model.advs ||= [];
            const noneSelectedAdvertArr = advs.filter((e) => !this.model.advs.find((e1) => e1.code === e.code));
            this.model.advs.push(...noneSelectedAdvertArr);
        },
        handlePickUpManagedDept() {
            this.$refs.pickUpManagedDept.show();
        },
        handleSelectManagedDeptOk(dept = []) {
            //由于是选择单个机构，所以只取一个
            this.model.dept = dept[0] || '';
        },
        handleAdvDelete(idx) {
            this.model.advs.splice(idx, 1);
        },
        isImgAdvertFileType(advertFileType) {
            return AdvertUtils.isImgAdvertFileType(advertFileType);
        },
        isVideoAdvertFileType(advertFileType) {
            return AdvertUtils.isVideoAdvertFileType(advertFileType);
        },
        handlePreview(advertFileType, advertUrl) {
            this.$refs.advertPreview.showDialog(advertFileType, advertUrl);
        },
        handleSave() {
            this.$refs.createForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                if (this.form.advertCount > 10) {
                    this.$message.error('同一门店一天内投放的广告不能超过10个');
                    return;
                }
                await postAfterConfirmed('/advert/launch/createByDept', this.form, null, undefined, {
                    timeout: 2 * 60 * 1000,
                });
                this.goBackAndReload();
            });
        },
    },
};
</script>

<style scoped>
.AdvToStore .el-tag {
    margin-left: 8px;
}
</style>
